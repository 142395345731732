import "./post.css";

const Comment = ({ commentObj }) => {
  return (
    <div className="commentBlock">
      <p><i>{commentObj.username}</i></p>
      <p>{commentObj.content}</p>
    </div>
  );
};

export default Comment;
