import { useEffect, useState } from "react";
import "./post.css";
import Comment from "./comment";

const Post = ({ postObj, array, onNew }) => {
  const [username, setName] = useState("");
  const [content, setContent] = useState("");
  const [comments, setComments] = useState(postObj.comments);
  let postObjNew = postObj;
  let arrayNew = array;

  useEffect(() => {
    const getComments = () => {
      setComments(postObj.comments);
    };
    getComments();
  }, [postObj.comments]);

  const getIndex = (Obj) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i]["postID"] === Obj.postID) {
        return i;
      }
    }
    return -1;
  };

  const submit = async () => {
    //build JSON object
    var commentJSON = {
      username: username,
      content: content,
    };

    // function: POST request to the API
    const setNewPost = async () => {
      const resp = await fetch("https://my-worker.enome.workers.dev/", {
        method: "POST",
        body: JSON.stringify(postObj),
        headers: { "Content-type": "application/json" },
      });
      return resp;
    };

    // check if message is empty
    if (Object.keys(content).length === 0) {
      // reject post if true
      window.alert("Comment cannot be empty");
      return;
    }
    // check if username is empty; post as anonymous if true
    if (Object.keys(username).length === 0) {
      commentJSON.username = "Anonymous";
    }
    // build new comments array then set it as comment array in object
    const newCommentArray = postObj.comments.concat([commentJSON]);
    postObjNew.comments = newCommentArray;

    // POST request to the API
    const response = await setNewPost();
    // if successful, update the current user's page with new content
    if (response.ok) {
      arrayNew[parseInt(getIndex(postObjNew))] = postObjNew;
      setComments(arrayNew);
    }
  };

  const updateName = (evt) => setName(evt.target.value);
  const updateContent = (evt) => setContent(evt.target.value);

  return (
    <div className="PostBlock">
      <h1>{postObj.title}</h1>
      <h5>Posted by {postObj.username}</h5>
      <h3>{postObj.content}</h3>
      {postObj.media !== "" && <img src={postObj.media} alt="Post Media" />}
      <hr />
      {comments.length !== 0 && (
        <h3 style={{ margin: "0.35rem 0 0.5rem" }}>Comments</h3>
      )}
      {comments.length !== 0 &&
        comments.map((comment) => (
          <Comment key={comment.content} commentObj={comment} />
        ))}
      <div className="addComment">
        <h3>Add Comment</h3>
        <input
          id="nameComment"
          type="text"
          onChange={updateName}
          placeholder="Enter Username (Optional)"
        />
        <textarea
          id="comment"
          type="text"
          onChange={updateContent}
          placeholder="Enter Comment"
        />
        <button onClick={submit}>Post Comment</button>
      </div>
    </div>
  );
};

export default Post;
