import './App.css';
import { useEffect, useState, useCallback } from "react";

import Post from './components/post';
import NewPost from './components/newPost';


const url = "https://my-worker.enome.workers.dev/";

function App() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(url, {
        method: "GET"
      });
      const postsResp = await resp.json();
      setPosts(postsResp.posts.reverse());
    };
    getPosts();
  }, []);

  const wrapperSetPosts = useCallback(array => {
    setPosts(array);
  }, [setPosts])

  return (
    <div className="App">
      <NewPost 
      count={ posts.length }
      array = { posts }
      onNew={ wrapperSetPosts }
      />
      <div className="Post">
        {posts.map(post =>
            <Post 
            key={ post.postID }
            postObj={ post }
            array = { posts }
            onNew={ wrapperSetPosts }
            />
        )}
      </div>
    </div>
  );
}

export default App;
