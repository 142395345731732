import "./post.css";
import React, { useState } from "react";

const NewPost = ({ count, array, onNew }) => {
  const postCount = count.toString();
  const [title, setTitle] = useState("");
  const [username, setName] = useState("");
  const [content, setContent] = useState("");
  const [media, setMedia] = useState("");

  // update the posts state in Parent
  const updateUserPage = (array) => {
    onNew(array);
  };

  const submit = async () => {
    //build JSON object
    var bodyJSON = {
      postID: postCount,
      title: title,
      username: username,
      content: content,
      media: media,
      comments: [],
    };

    // function: POST request to the API
    const setNewPost = async () => {
      const resp = await fetch("https://my-worker.enome.workers.dev/", {
        method: "POST",
        body: JSON.stringify(bodyJSON),
        headers: { "Content-type": "application/json" },
      });
      return resp;
    };

    // check if title and message is empty
    if (Object.keys(title).length === 0 || Object.keys(content).length === 0) {
      // reject post if true
      window.alert("Title and Message cannot be empty");
      return;
    }
    // check if username is empty; post as anonymous if true
    if (Object.keys(username).length === 0) {
      bodyJSON.username = "Anonymous";
    }

    // POST request to the API
    const response = await setNewPost();
    // if successful, update the current user's page with new content
    if (response.ok) {
      updateUserPage([bodyJSON].concat(array));
    }
  };

  const updateTitle = (evt) => setTitle(evt.target.value);
  const updateName = (evt) => setName(evt.target.value);
  const updateContent = (evt) => setContent(evt.target.value);
  const updateMedia = (evt) => setMedia(evt.target.value);

  return (
    <div className="PostBlock">
      <input
        id="title"
        type="text"
        onChange={updateTitle}
        placeholder="Enter Title"
      />
      <input
        id="name"
        type="text"
        onChange={updateName}
        placeholder="Enter Username (Optional)"
      />
      <textarea
        id="content"
        type="text"
        onChange={updateContent}
        placeholder="Enter Message"
      />
      <input
        id="media"
        type="text"
        onChange={updateMedia}
        placeholder="Paste IMG/GIF URL (Optional)"
      />
      <button onClick={submit}>Post</button>
    </div>
  );
};

export default NewPost;
